import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import HeroV2 from '../components/HeroV2';
import Image from '../components/Image';
import OutboundLink from '../components/OutboundLink';
import FooterCallout from './partials/_FooterCallout';
import Girls4TechCallout from './partials/_Girls4TechCallout';
import HeroImage from '../images/About-hero-img-1400x366-2x.jpg';

const textOverlay = (
  <div className="home__text_overlay_container">
    <Container fullWidth={true}>
      <div className="home__text_overlay_inner_container">
        <span className="home__text_overlay_title">About The Program</span>
      </div>
    </Container>
  </div>
);

const AboutPage = ({ data }) => (
  <Layout title="About the Program">
    <HeroV2
      mediaSource="About-hero-img-1400x366-2x.jpg"
      textOverlay={textOverlay}
    />
    <Section className="about__intro">
      <Container>
        <Row>
          <Column size={6}>
            <h2>
              Inspiring a New Generation of STEM Heroes Through the Power of
              Cybersecurity
            </h2>
            <p>
              Today’s STEM challenges require digitally savvy problem solvers
              who can use tech to develop their superpowers. Help your students
              bring a fresh new perspective to critical real-world issues in
              cyber with hands-on interactives and empowering career profiles
              from <strong>Girls4Tech</strong>. Every STEM hero needs an origin
              story!
            </p>
            <OutboundLink
              to="https://girls4tech.com"
              className="text-bold"
              withIcon
            >
              Visit Girls4Tech.com
            </OutboundLink>
          </Column>
          <Column size={6}>
            <Image filename="img-orange-laptop.png" />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="about__partners">
      <Container>
        <h2 className="about__partners-heading">About the Partners</h2>
        <Row>
          <Column size={6} className="about-mission__content">
            <Image
              filename="logo-mastercard.svg"
              className="about__partners-logos mastercard"
            />
            <p>
              <strong>Mastercard</strong> has been transforming how people pay
              and get paid for more than 50 years, with a commitment to
              innovation that has supported growing businesses, improved the
              consumer payments experience, and advanced a more inclusive
              financial system around the globe. Mastercard’s breakthroughs
              power one of the world’s fastest payment processing networks to
              make transactions faster, easier, more accessible, and more
              secure. In addition to technology, Mastercard believes that
              fostering a culture of innovation is critical to success, and this
              requires a diversely talented team with curious minds and big
              ideas. The company also has a strong connection to working with
              developers and entrepreneurs to enable advances in the payments
              ecosystem of the future.
            </p>
          </Column>
          <Column size={6} className="about-mission__content">
            <Image
              filename="de-logo-positive.png"
              className="about__partners-logos de"
            />
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in over 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at  
              <OutboundLink to="https://www.discoveryeducation.com">
                www.discoveryeducation.com
              </OutboundLink>
              .
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <FooterCallout
      text={
        <>
          Explore crucial 21st century skills for young women and men in{' '}
          <em>every</em> community.
        </>
      }
      className="about__crucial-skills"
    />
    <Girls4TechCallout />
  </Layout>
);

export default AboutPage;
